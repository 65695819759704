import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Row, Col, Table } from 'react-bootstrap';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk,
  faSortAmountUp,
  faSortAlphaUp,
  faWrench
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import Switch from 'react-switch';
import { Range, getTrackBackground } from 'react-range';

import lodash from 'lodash';

import './stats.scss';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { CustomPopover } from '../../../modules/cs/common/components/custom-popover';
import { EmployeePopover } from '../../../modules/cs/common/components/employee-popover';
import { FilterBar } from '../../../modules/cs/common/components/filter-bar/filter-bar';
import { SpecialitiesArray } from '../../../modules/cs/employee/model/specialities-array';
import { StatCalculator } from '../../../modules/cs/employee/utils/stat-calculator';
import { IEmployeeNode } from '../../../modules/common/model/graphql-types';
import { ConvertToEmployeeModel } from '../../../modules/cs/common/utils/DataConverters';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { useSelector, useDispatch } from 'react-redux';
import { ToggleShowConfiguration } from '../../../modules/layout/actions/Layout.actions';
import { RootState } from '../../../state/Store';

interface IEmployeeNodes {
  nodes: IEmployeeNode[];
}

interface IEmployeeEntry {
  allEmployees: IEmployeeNodes;
}

interface IProps {
  data: IEmployeeEntry;
}

const StatsPage: React.FC<IProps> = ({ data }) => {
  const REQUIRED_EMPLOYEES_PER_FACTION = 3;
  const [visibleEmployees, setVisibleEmployees] = useState(
    data.allEmployees.nodes
  );

  const FILTER_STORAGE_KEY = 'stats-filter';
  const OPTS_STORAGE_KEY = 'stats-opts';
  const GLOBAL_OPTS_STORAGE_KEY = 'global-opts';

  const isBrowser = typeof window !== 'undefined';
  let currentOpts = isBrowser
    ? JSON.parse(window.sessionStorage.getItem(OPTS_STORAGE_KEY))
    : {};
  if (!currentOpts) {
    currentOpts = {};
  }
  let currentGlobalOpts = isBrowser
    ? JSON.parse(window.sessionStorage.getItem(GLOBAL_OPTS_STORAGE_KEY))
    : {};
  if (!currentGlobalOpts) {
    currentGlobalOpts = {};
  }

  const [activeFilters, setActiveFilters] = useState(
    isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY))
      : {} || {}
  );
  const [sortOption, setSortOption] = useState(currentOpts.sort || null);
  const [region, setRegion] = useState(currentGlobalOpts.region || null);
  const [showUnreleased, setShowUnreleased] = useState(
    currentOpts.unreleased || false
  );
  const [statsLevel, setStatsLevel] = useState([100]);
  const [statsIncludeLoyalty, setStatsIncludeLoyalty] = useState(false);
  const [statsShowPVP, setStatsShowPVP] = useState(false);

  let factions = [];

  const grps = lodash.groupBy(data.allEmployees.nodes, 'title');
  Object.keys(grps).forEach((key) => {
    if (grps[key].length >= REQUIRED_EMPLOYEES_PER_FACTION) {
      factions.push({
        label: key,
        value: key
      });
    }
  });

  factions = [{ label: 'Any Faction', value: 'all' }].concat(
    lodash.orderBy(factions, 'label')
  );

  const filterConfig = {
    filters: [
      { key: 'searchText', type: 'search', placeholder: 'Search employees...' },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'N', value: 'n' },
          { label: 'R', value: 'r' },
          { label: 'SR', value: 'sr' },
          { label: 'SSR', value: 'ssr' },
          { label: 'A-SSR', value: 'a-ssr' }
        ]
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Counter',
            tooltip: 'Counter',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/type_counter.png"
                width={20}
                alt="Counter"
              />
            )
          },
          {
            value: 'Mech',
            tooltip: 'Mech',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/type_mech.png"
                width={20}
                alt="Mech"
              />
            )
          },
          {
            value: 'Soldier',
            tooltip: 'Soldier',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/type_soldier.png"
                width={20}
                alt="Soldier"
              />
            )
          },
          {
            value: 'Corrupted Object',
            tooltip: 'Corrupted Object',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/type_co.png"
                width={20}
                alt="Corrupted Object"
              />
            )
          }
        ]
      },
      {
        key: 'role',
        type: 'dropdown',
        values: [
          { label: 'Any Role', value: 'all' },
          {
            label: 'Defender',
            value: 'defender',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/role_defender.png"
                width={20}
                alt="Defender"
              />
            )
          },
          {
            label: 'Ranger',
            value: 'ranger',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/role_ranger.png"
                width={20}
                alt="Ranger"
              />
            )
          },
          {
            label: 'Siege',
            value: 'siege',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/role_siege.png"
                width={20}
                alt="Siege"
              />
            )
          },
          {
            label: 'Sniper',
            value: 'sniper',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/role_sniper.png"
                width={20}
                alt="Sniper"
              />
            )
          },
          {
            label: 'Striker',
            value: 'striker',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/role_striker.png"
                width={20}
                alt="Striker"
              />
            )
          },
          {
            label: 'Supporter',
            value: 'supporter',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/role_support.png"
                width={20}
                alt="Supporter"
              />
            )
          },
          {
            label: 'Tower',
            value: 'tower',
            image: (
              <StaticImage
                src="../../../images/counterside/icons/role_tower.png"
                width={20}
                alt="Tower"
              />
            )
          }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: SpecialitiesArray },
      { key: 'faction', type: 'dropdown', values: factions },
      { key: 'reset', type: 'reset' }
    ],
    defaults: isBrowser
      ? JSON.parse(window.sessionStorage.getItem(FILTER_STORAGE_KEY)) || {}
      : {}
  };

  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };

  useEffect(() => {
    let filtered = data.allEmployees.nodes;
    console.log('recalc...');

    if (showUnreleased) {
      filtered = filtered.filter(
        (emp) =>
          emp.regions.indexOf(region) === -1 && emp.regions.indexOf('KR') !== -1
      );
    }

    if (region && !showUnreleased) {
      filtered = filtered.filter((emp) => emp.regions.indexOf(region) > -1);
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.fullName
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        if (activeFilters.rarity.toLowerCase() !== 'a-ssr') {
          filtered = filtered.filter(
            (emp) =>
              emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase() &&
              emp.isAwakened === false
          );
        } else {
          filtered = filtered.filter(
            (emp) =>
              emp.rarity.toLowerCase() === 'ssr' && emp.isAwakened === true
          );
        }
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.indexOf(activeFilters.type) > -1
        );
      }
      if (activeFilters.role && activeFilters.role !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.role.toLowerCase() === activeFilters.role.toLowerCase()
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.speciality &&
            emp.speciality.length > 0 &&
            emp.speciality.indexOf(activeFilters.speciality) > -1
        );
      }
      if (activeFilters.faction && activeFilters.faction !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.title.toLowerCase() === activeFilters.faction.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
    } else if (sortOption === 'stats_hp') {
      if (statsShowPVP) {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pvp.hp <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pvp.hp
            ? 1
            : -1
        );
      } else {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pve.hp <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pve.hp
            ? 1
            : -1
        );
      }
    } else if (sortOption === 'stats_atk') {
      if (statsShowPVP) {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pvp.atk <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pvp.atk
            ? 1
            : -1
        );
      } else {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pve.atk <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pve.atk
            ? 1
            : -1
        );
      }
    } else if (sortOption === 'stats_def') {
      if (statsShowPVP) {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pvp.def <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pvp.def
            ? 1
            : -1
        );
      } else {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pve.def <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pve.def
            ? 1
            : -1
        );
      }
    } else if (sortOption === 'stats_critical') {
      if (statsShowPVP) {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pvp.critical <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pvp.critical
            ? 1
            : -1
        );
      } else {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pve.critical <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pve.critical
            ? 1
            : -1
        );
      }
    } else if (sortOption === 'stats_hit') {
      if (statsShowPVP) {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pvp.hit <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pvp.hit
            ? 1
            : -1
        );
      } else {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pve.hit <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pve.hit
            ? 1
            : -1
        );
      }
    } else if (sortOption === 'stats_evade') {
      if (statsShowPVP) {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pvp.evade <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pvp.evade
            ? 1
            : -1
        );
      } else {
        filtered = filtered.sort((a, b) =>
          StatCalculator(a, statsLevel[0], statsIncludeLoyalty).pve.evade <
          StatCalculator(b, statsLevel[0], statsIncludeLoyalty).pve.evade
            ? 1
            : -1
        );
      }
    }

    if (isBrowser) {
      window.sessionStorage.setItem(
        FILTER_STORAGE_KEY,
        JSON.stringify(activeFilters || {})
      );
      const newOpts = {
        sort: sortOption,
        unreleased: showUnreleased
      };
      window.sessionStorage.setItem(
        OPTS_STORAGE_KEY,
        JSON.stringify(newOpts || {})
      );
      const newGlobalOpts = {
        region: region
      };
      window.sessionStorage.setItem(
        GLOBAL_OPTS_STORAGE_KEY,
        JSON.stringify(newGlobalOpts || {})
      );
    }

    setVisibleEmployees(filtered);
  }, [
    activeFilters,
    data.allEmployees.nodes,
    sortOption,
    region,
    showUnreleased,
    isBrowser,
    statsShowPVP,
    statsLevel,
    statsIncludeLoyalty
  ]);

  const serverOptions = [
    { value: 'KR', label: 'KR server' },
    { value: 'Global', label: 'Global server' }
  ];

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: 'stats_hp', label: 'Sort by HP' },
    { value: 'stats_atk', label: 'Sort by ATK' },
    { value: 'stats_def', label: 'Sort by DEF' },
    { value: 'stats_critical', label: 'Sort by CRIT' },
    { value: 'stats_hit', label: 'Sort by HIT' },
    { value: 'stats_evade', label: 'Sort by EVA' }
  ];

  const regionChangeHandler = (e) => {
    if (e.value === 'KR') {
      setShowUnreleased(false);
    }
    setRegion(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  let defaultRegion = region
    ? serverOptions.find((opt) => opt.value === region)
    : null;
  if (defaultRegion === null) {
    defaultRegion = serverOptions[0];
    setRegion(defaultRegion.value);
  }

  const unreleasedChangeHandler = (e) => {
    setShowUnreleased(e);
  };

  const statsLevelChange = (values) => {
    setStatsLevel(values);
  };

  const model = useSelector((state: RootState) => ({
    layout: state.layout
  }));

  const dispatch = useDispatch();

  return (
    <DashboardLayout className={'generic-page characters-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/database">Database</Link>
        </li>
        <li className="divider">/</li>
        <li>Character stats</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_stats.png"
            alt="Stats"
          />
        </div>
        <div className="page-details">
          <h1>Character stats</h1>
          <h2>Stats of all characters in Counter Side.</h2>
        </div>
      </div>
      <div className="configuration">
        <h5>
          <FontAwesomeIcon icon={faWrench} width="18" />
          Configuration{' '}
          <Switch
            checked={model.layout.showConfiguration}
            onChange={() => dispatch(ToggleShowConfiguration())}
            onColor="#009EEC"
            offColor="#484950"
            className="switch"
          />
        </h5>
        <div
          className={`configuration-container ${model.layout.showConfiguration}`}
        >
          <div className="server">
            <span>Region</span>
            <Select
              options={serverOptions}
              className="custom-dropdown"
              isSearchable={false}
              onChange={regionChangeHandler}
              defaultValue={defaultRegion}
              classNamePrefix="custom-dropdown-inner"
            />
            <div className="switch-unreleased">
              <Switch
                checked={showUnreleased}
                onChange={unreleasedChangeHandler}
                disabled={region === 'KR'}
                onColor="#009EEC"
                offColor="#484950"
                className="switch"
              />
              <span className="text">Show unreleased</span>
            </div>
          </div>
          <div className="level">
            <div className="level-slider-header">
              <span>Level</span>
              <span className="level-number">{statsLevel[0]}</span>
            </div>
            <Range
              step={1}
              min={1}
              max={110}
              values={statsLevel}
              onChange={statsLevelChange}
              renderTrack={({ props, children }) => (
                <div
                  role="button"
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '5px',
                      width: '100%',
                      borderRadius: '4px',
                      background: getTrackBackground({
                        values: statsLevel,
                        colors: ['#009EEC', '#484950'],
                        min: 1,
                        max: 110
                      }),
                      alignSelf: 'center'
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '27px',
                    width: '27px',
                    borderRadius: '5px',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      height: '16px',
                      width: '5px',
                      backgroundColor: isDragged ? '#009EEC' : '#484950'
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className="options">
            <div className="custom-switch">
              <Switch
                checked={statsIncludeLoyalty}
                onChange={(value) => setStatsIncludeLoyalty(value)}
                onColor="#009EEC"
                offColor="#484950"
                className="switch"
              />
              <span className="text">Include 100 loyalty bonus</span>
            </div>
            <div className="custom-switch pvp-pve">
              <Switch
                checked={statsShowPVP}
                onChange={(value) => setStatsShowPVP(value)}
                onColor="#009EEC"
                offColor="#484950"
                className="switch"
              />
              <span className="text">Show PVP stats instead</span>
            </div>
          </div>
        </div>
      </div>
      <SectionHeader
        title="Stats"
        subtitle={
          <span className="additional-text">
            Showing {visibleEmployees.length} character
            {visibleEmployees.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar g-2">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="stats-page">
        <Row>
          <Col>
            <Table className="stats-table" striped bordered responsive>
              <thead>
                <tr>
                  <th scope="col" onClick={() => setSortOption('+fullName')}>
                    Name
                    {sortOption && sortOption === '+fullName' && (
                      <span>
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th scope="col" onClick={() => setSortOption('stats_hp')}>
                    HP
                    {sortOption && sortOption === 'stats_hp' && (
                      <span>
                        <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th scope="col" onClick={() => setSortOption('stats_atk')}>
                    ATK
                    {sortOption && sortOption === 'stats_atk' && (
                      <span>
                        <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th scope="col" onClick={() => setSortOption('stats_def')}>
                    DEF
                    {sortOption && sortOption === 'stats_def' && (
                      <span>
                        <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    onClick={() => setSortOption('stats_critical')}
                  >
                    CRIT
                    {sortOption && sortOption === 'stats_critical' && (
                      <span>
                        <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th scope="col" onClick={() => setSortOption('stats_hit')}>
                    HIT
                    {sortOption && sortOption === 'stats_hit' && (
                      <span>
                        <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th scope="col" onClick={() => setSortOption('stats_evade')}>
                    EVA
                    {sortOption && sortOption === 'stats_evade' && (
                      <span>
                        <FontAwesomeIcon icon={faSortAmountUp} width="18" />
                      </span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {visibleEmployees.map((emp, index) => {
                  const image = emp.smallAvatar
                    ? getImage(emp.smallAvatar.localFile.childImageSharp)
                    : null;
                  return (
                    <tr key={index}>
                      <th scope="row">
                        <CustomPopover
                          enabled
                          customClass="character-hover-box"
                          popover={
                            <EmployeePopover
                              employee={ConvertToEmployeeModel(emp)}
                            />
                          }
                        >
                          <Link to={'/counter-side/characters/' + emp.slug}>
                            <div className={`avatar ${emp.rarity}`}>
                              <GatsbyImage
                                image={image}
                                alt={`${emp.fullName}`}
                              />
                            </div>
                            <span className="emp-name">{emp.shortName}</span>
                            {emp.showNewTag && (
                              <span className="tag new">NEW!</span>
                            )}
                            {emp.hasBeenBuffedRecently && (
                              <span className="tag buffed">Updated</span>
                            )}
                          </Link>
                        </CustomPopover>
                      </th>
                      <td className="stat">
                        {emp.stats && (
                          <>
                            {!statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pve.hp.toLocaleString()}
                            {statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pvp.hp.toLocaleString()}
                          </>
                        )}
                      </td>
                      <td className="stat">
                        {emp.stats && (
                          <>
                            {!statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pve.atk.toLocaleString()}
                            {statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pvp.atk.toLocaleString()}
                          </>
                        )}
                      </td>
                      <td className="stat">
                        {emp.stats && (
                          <>
                            {!statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pve.def.toLocaleString()}
                            {statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pvp.def.toLocaleString()}
                          </>
                        )}
                      </td>
                      <td className="stat">
                        {emp.stats && (
                          <>
                            {!statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pve.critical.toLocaleString()}
                            {statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pvp.critical.toLocaleString()}
                          </>
                        )}
                      </td>
                      <td className="stat">
                        {emp.stats && (
                          <>
                            {!statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pve.hit.toLocaleString()}
                            {statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pvp.hit.toLocaleString()}
                          </>
                        )}
                      </td>
                      <td className="stat">
                        {emp.stats && (
                          <>
                            {!statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pve.evade.toLocaleString()}
                            {statsShowPVP &&
                              StatCalculator(
                                emp,
                                statsLevel[0],
                                statsIncludeLoyalty
                              ).pvp.evade.toLocaleString()}
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {visibleEmployees.length === 0 && (
              <Col className="no-results">
                <StaticImage
                  src="../../../images/counterside/no_results.png"
                  alt="No results"
                />
                <p>No character found. Try changing your filters</p>
              </Col>
            )}
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default StatsPage;

export const Head: React.FC = () => (
  <Seo
    title="Character stats | Counter Side | Prydwen Institute"
    description="Stats of all characters in Counter Side."
  />
);

export const pageQuery = graphql`
  query {
    allEmployees: allContentfulEmployee(
      sort: { fields: shortName, order: ASC }
    ) {
      nodes {
        id
        updatedAt
        createdAt
        unitId
        fullName
        slug
        shortName
        seaReleaseDate
        krReleaseDate
        globalReleaseDate
        regions
        rarity
        rarityNumber
        rarityClassName
        title
        cost
        type
        role
        movementType
        attackType
        role
        smallAvatar {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        movementType
        attackType
        speciality
        isAwakened
        newRatings {
          pvp_sea
          pvp_global
          pve_early
          pve_late
        }
        hasBeenBuffedRecently
        stats {
          perLevel {
            atk
            critical
            def
            evade
            hit
            hp
          }
          base {
            hp
            atk
            def
            hit
            critical
            evade
          }
        }
        isRatingPending
        showNewTag
      }
    }
  }
`;
